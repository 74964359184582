import styled from "styled-components";
import { StyledPageTemplateWrapper } from "../../../styles/styledPageTemplate";
import editIcon from "../../../../assets/images/icons/edit-icon.svg";
import {device, hideOnMedia} from "../../../styles/responsive/responsiveUtils";

export const StyledClientDetailsWrapper = styled(StyledPageTemplateWrapper)`
  padding-top: 25px;

  .account-wrapper {
    padding-top: 35px;
    margin: 10px 0 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    @media screen and ${device(599, 'min')} and ${device(799)} {
      gap: 4%;
      margin: 10px 0 20px;;
    }

    @media screen and ${device(800, 'min')} {
      gap: 3%;
      margin: 10px 0 20px;
    }

    @media screen and ${device('desktop', 'min')} {
      margin: 10px 23px 20px;
    }

    &__info {
      width: 100%;
      display: flex;
      flex-direction: column;

      @media screen and ${device(599, 'min')} and ${device(800)} {
        width: 60%;
      }

      @media screen and ${device(800, 'min')} {
        width: 70%;
      }
    }

    &__stats {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      gap: 4%;

      @media screen and ${device(800, 'min')} {
        gap: 2%;
      }
    }

    &__other {
      margin-bottom: 33px;
      @media screen and ${device('md')} {
        justify-content: space-around;
        margin-bottom: 0;
      }

      & > div {
        width: calc((100% - 80px) / 5);
        @media screen and ${device('md')} {
          width: 100px;
          margin-bottom: 33px;
        }
      }
    }

    @media screen and (max-width: 1100px) {
      //grid-template-rows: 250px 1fr;
      grid-template-columns: unset;
    }
    //@media screen and (max-width: 992px) {
    //  grid-template-columns: 100%;
    //}
  }

  .account-statistics-table {
    border: 1px solid ${({ theme }) => theme.tableRow.borderColor};
    min-width: min-content !important;
      
    &__head, &__row {
      grid-template-columns: repeat(1, 72px) repeat(3, 60px) repeat(1, 70px) repeat(2, minmax(125px, 1fr));
      border: none;
    }

    &__currency {
      span {
        margin: 0 auto;
      }
    }

    &__green {
      color: #298f4e;
    }

    &__red {
      color: #942a39;
    }
  }

  .default-tabs {
    gap: 25px;

    .rc-tabs-tab {
      font-weight: 500 !important;
      color: ${({ theme }) => theme.subTitleColor} !important;
      margin-right: 25px !important;
        
        @media screen and (max-width: 765px) {
            .rc-tabs-tab-btn {
                font-size: 23px !important;
            }
        }
        
        @media screen and (max-width: 695px) {
            .rc-tabs-tab-btn {
                font-size: 20px !important;
            }
        }
        
        @media screen and (max-width: 626px) {
            .rc-tabs-tab-btn {
                font-size: 18px !important;
            }
        }

        @media screen and (max-width: 599px) {
            .rc-tabs-tab-btn {
                font-size: 25px !important;
            }
        }
        
        @media screen and (max-width: 475px) {  
            margin-right: 20px !important;  
        }

        @media screen and (max-width: 450px) {
            .rc-tabs-tab-btn {
                font-size: 22px !important;
            }
        }

        @media screen and (max-width: 410px) {
            .rc-tabs-tab-btn {
                font-size: 20px !important;
            }
        }

        @media screen and (max-width: 380px) {
            .rc-tabs-tab-btn {
                font-size: 18px !important;
            }
            margin-right: 15px !important;
        }

        @media screen and (max-width: 340px) {
            .rc-tabs-tab-btn {
                font-size: 15px !important;
            }
        }
        
        &.rc-tabs-tab-active {
        border: none;
        color: ${({ theme }) => theme.defaultChangeColor} !important;
      }
    }
  }
`;

const bgColor = (color) => {
  switch (color) {
    case "wins":
      return `
        .activity-img {
          background-color: #298f4e;
        }
        b {
          color: #298f4e;
        }
      `
    case "lose":
      return `
        .activity-img {
          background-color: #942a39;
        }
        b {
          color: #942a39;
        }
      `
    default:
      return `
        .activity-img {
          background-color: #307cad;
        }
      `;
  }
}

export const StyledBetsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin: 2px 23px 0;
  gap: 33px 20px;
  padding-top: 25px;

  @media screen and ${device('desktop')}{
    gap: 1.5% 1%;
    margin: 0;
  }
`;

export const StyledAccountActivityItem = styled.div`
    width: 100%;
    min-height: 75px;
    padding: 12px 20px;
    line-height: 1;
    background-color: ${({theme}) => theme.accountActivityItemBg};
    box-shadow: ${({theme}) => theme.accountActivityItemShadow};
    border-radius: 2px;
    text-align: center;
    margin-bottom: 35px;

    .bets-details-title {
        line-height: 24px;
        padding-bottom: 0 !important;
        font-weight: 500;
        display: block;
    }

    @media screen and (max-width: 1100px) {
        .bets-details-title .nickname{
            line-height: 18px;
        }
    }
    
    .bets-details-description {
        display: block;
        margin-top: 6px;
    }

    .nickname {
        font-weight: bold;
    }

    @media screen and ${device(379, 'min')} and ${device(800)} {
        width: 48%;
    }

    .activity-img {
        position: relative;
        width: 40px;
        height: 40px;
        margin: -35px auto 7px;
        border: 0 solid #000000;
        border-radius: 100%;
        box-shadow: ${({theme}) => theme.accountActivityImgShadow};
        display: flex;
        align-items: center;
        justify-content: center;

        & > img {
            width: 60%;
        }
    }

    .user-avatar img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
    }

    & .user-group {
        width: 34px;
        font-size: 9px;
        bottom: -3px;
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        text-shadow: 1px 2px 3px rgba(0, 0, 0, 1);
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, 0px);
        font-family: "Segoe UI Classic";
        line-height: normal;
        font-weight: 600;
    }

    & .user-group.mod {
        background: #2eab5b;
        color: #ffffff !important;
    }

    & .user-group.admin {
        background: #ab392e;
        color: #ffffff !important;
    }

    & .user-group.vip {
        background: #1f9ce2;
        color: #ffffff !important;
    }

    & .user-group.leg {
        background: #ab392e;
        color: #ffffff !important;
    }
    
    .currency {
        background-color: transparent !important;
        box-shadow: none;
    }

    b {
        display: block;
        color: ${({theme}) => theme.subTitleColor};
        font-size: 18px;
        font-weight: 500;
        padding-bottom: 10px;

        @media screen and ${device('md')} {
            font-size: 14px;
        }

        @media screen and ${device('md', 'min')} and ${device('desktop')} {
            font-size: 16px;
        }

        &.win {
            color: ${({theme}) => theme.diceHistory.win};
        }

        &.lose {
            color: ${({theme}) => theme.diceHistory.lose};
        }
    }

    span {
        color: #777777;
        font-size: 16px;

        @media screen and ${device('desktop')} {
            font-size: 13px;
        }
    }

    @media screen and ${device('md')} {
        height: unset;
    }
    ${({color}) => bgColor(color)};

    .userIcon {
        border-radius: 50%;
        background: ${({theme}) => theme.elementBg};
    }

    &.box-1-3, &.box-1-4 {
        height: unset;
        width: 210px;
        padding: 12px 16px;

        @media screen and ${device('sm')} {
            width: 49.5%;
        }

        @media screen and ${device('sm', 'min')} and ${device('desktop')} {
            width: 27%;
        }

        b {
            padding-bottom: 3px;
            overflow-x: auto;

            &::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 12px;
                height: 7px;
                background-color: ${({theme}) => theme.body};
            }

            &::-webkit-scrollbar-thumb {
                height: 8px;
                border: 1px solid ${({theme}) => theme.body};
                background-color: ${({theme}) => theme.neutralColor};
                border-radius: 4px;
            }
        }
    }

    &.box-1-4 {
        width: 150px;
        padding: 12px 0;

        @media screen and ${device('sm')} {
            width: 49.5%;
        }

        @media screen and ${device('sm', 'min')} and ${device('desktop')} {
            width: 19%;
        }
    }

    .level {
        position: absolute;
        top: -4px;
        right: -4px;
        width: 20px;
        height: 20px;
        color: ${({theme}) => theme.subTitleColor};
        text-align: center;
        font-size: 11px;
        border-radius: 50%;
        background: ${({theme}) => theme.accountActivityItemBg};
        border: ${({theme}) => theme.accountFriends.item.level.border};
        z-index: 10;
        box-shadow: -1px 1px 5px rgb(0 0 0 / 80%);
        cursor: pointer;
        line-height: normal;
    }

    .level.level10, .level.level11, .level.level12, .level.level13, .level.level14, .level.level15, .level.level16, .level.level17, .level.level18, .level.level19 {
        border-color: #e2a61f !important;
    }

    .level.level20, .level.level21, .level.level22, .level.level23, .level.level24, .level.level25, .level.level26, .level.level27, .level.level28, .level.level29 {
        border-color: #1f5be2 !important;
    }

    .level.level30, .level.level31, .level.level32, .level.level33, .level.level34, .level.level35, .level.level36, .level.level37, .level.level38, .level.level39 {
        border-color: #1fe244 !important;
    }

    .level.level40, .level.level41, .level.level42, .level.level43, .level.level44, .level.level45, .level.level46, .level.level47, .level.level48, .level.level49 {
        border-color: #e21fbc !important;
    }

    .level.level50, .level.level51, .level.level52, .level.level53, .level.level54, .level.level55, .level.level56, .level.level57, .level.level58, .level.level59 {
        border-color: #e2441f !important;
    }

    .level.level60, .level.level61, .level.level62, .level.level63, .level.level64, .level.level65, .level.level66, .level.level67, .level.level68, .level.level69 {
        border-color: #e21f5b !important;
    }

    .level.level70, .level.level71, .level.level72, .level.level73, .level.level74, .level.level75, .level.level76, .level.level77, .level.level78, .level.level79 {
        border-color: #e21f3a !important;
    }


    .multiply {
        width: 30px;
    }

    .percent {
        width: 25px;
    }
`;

export const StyledAccountActivityItemLg = styled(StyledAccountActivityItem)`
  @media screen and ${device(800, 'min')} {
    width: 32%;
  }
`;

export const StyledAccountActivityItemSm = styled(StyledAccountActivityItem)`
  @media screen and ${device(800, 'min')} {
    width: 18.4%;
  }
`;

export const StyledAccountImage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media screen and ${device(599, 'min')} and ${device(800)} {
    width: 36%;
  }

  @media screen and ${device(800, 'min')}  {
    width: 27%;
  }
  
  .user-info {
    width: 100%;
    padding: 0;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.accountActivityItemBg};
    box-shadow: ${({ theme }) => theme.accountActivityItemShadow};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &__nickname {
      max-width: 220px;
      color: ${({ theme }) => theme.subTitleColor};
      font-size: 26px;
      margin: 0 0 16px 0;
      text-align: center;
      word-wrap: break-word;
      word-break: break-all;
      
      @media screen and ${device('desktop', 'min')} {
        font-size: 30px;
      }
    }

      & .user-group {
          width: 80px;
          left: 50%;
          margin-right: -50%;
          transform: translate(-50%, 0);
          font-size: 18px;
          bottom: 0;
          position: absolute;
          text-align: center;
          text-transform: uppercase;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          border-radius: 3px;
          text-shadow: 1px 2px 3px rgba(0, 0, 0, 1);
          font-family: "Segoe UI Classic";
          line-height: normal;
          font-weight: 600;
      }

      @media screen and (min-width: 1101px) {
          & .user-group {
              bottom: 10px;
          }
      }

      & .user-group.mod {
          background: #2eab5b;
          color: #ffffff !important;
      }

      & .user-group.admin {
          background: #ab392e;
          color: #ffffff !important;
      }
      
      & .user-group.vip {
          background: #1f9ce2;
          color: #ffffff !important;
      }

      & .user-group.leg {
          background: #ab392e;
          color: #ffffff !important;
      }      

    &__level {
      width: 30px;
      height: 30px;
      color: ${({ theme }) => theme.subTitleColor};
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      text-align: center;
      position: absolute;
      top: -40px;
      right: 10px;
      border: ${({theme}) => theme.accountFriends.item.level.border};
      background-color: ${({ theme }) => theme.accountActivityItemBg};
      border-radius: 100%;
      z-index: 10;
      cursor: pointer;
    }
      
      &__level.level10, &__level.level11, &__level.level12, &__level.level13, &__level.level14, &__level.level15, &__level.level16, &__level.level17, &__level.level18, &__level.level19 {
          border-color: #e2a61f !important;
      }

      &__level.level20, &__level.level21, &__level.level22, &__level.level23, &__level.level24, &__level.level25, &__level.level26, &__level.level27, &__level.level28, &__level.level29 {
          border-color: #1f5be2 !important;
      }

      &__level.level30, &__level.level31, &__level.level32, &__level.level33, &__level.level34, &__level.level35, &__level.level36, &__level.level37, &__level.level38, &__level.level39 {
          border-color: #1fe244 !important;
      }

      &__level.level40, &__level.level41, &__level.level42, &__level.level43, &__level.level44, &__level.level45, &__level.level46, &__level.level47, &__level.level48, &__level.level49 {
          border-color: #e21fbc !important;
      }

      &__level.level50, &__level.level51, &__level.level52, &__level.level53, &__level.level54, &__level.level55, &__level.level56, &__level.level57, &__level.level58, &__level.level59 {
          border-color: #e2441f !important;
      }

      &__level.level60, &__level.level61, &__level.level62, &__level.level63, &__level.level64, &__level.level65, &__level.level66, &__level.level67, &__level.level68, &__level.level69 {
          border-color: #e21f5b !important;
      }

      &__level.level70, &__level.level71, &__level.level72, &__level.level73, &__level.level74, &__level.level75, &__level.level76, &__level.level77, &__level.level78, &__level.level79 {
          border-color: #e21f3a !important;
      }

      &__level.level80, &__level.level81, &__level.level82, &__level.level83, &__level.level84, &__level.level85, &__level.level86, &__level.level87, &__level.level88, &__level.level89 {
          border-color: #e21f3a !important;
      }

      &__level.level90, &__level.level91, &__level.level92, &__level.level93, &__level.level94, &__level.level95, &__level.level96, &__level.level97, &__level.level98, &__level.level99 {
          border-color: #e21f3a !important;
      }      

    &__image {
      width: 140px;
      height: 140px;
      margin: -50px auto 0;
      background-color: ${({ theme }) => theme.accountActivityItemBg};
      box-shadow: ${({ theme }) => theme.accountAvatarShadow};
      border: 5px solid ${({ theme }) => theme.accountAvatarBorderColor};
      border-radius: 50%;
      overflow: hidden;
      display: inline-block;
      position: relative;
      
      @media screen and ${device('desktop', 'min')} {
        width: 160px;
        height: 160px;
        margin-bottom: 10px;
      }

      img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
      }
    }

    &_edit {
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4) url(${editIcon}) 50% 50% no-repeat;
        background-size: 25%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        cursor: pointer;
        border-radius: 45px;
        opacity: 0;
      }

      &:hover {
        &:before {
          opacity: 1;
        }
      }
    }

    span {
      padding-left: 7px;
    }

    &__footer {
      background: ${({ theme }) => theme.accountInfoFooterColor};
      width: 100%;

      height: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 26px;
      opacity: 0.8;

      @media screen and ${device('md', 'min')} {
        height: 53px;
      }

      & > button {
        border: none;
        opacity: 0.6;
        height: 53px;

        &:hover {
          opacity: 1;
          -webkit-transition: all 0.3s ease-out;
          -moz-transition: all 0.3s ease-out;
          -o-transition: all 0.3s ease-out;
          -ms-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
        }
      }
    }
  }

  //@media screen and (max-width: 992px) {
  //  padding-bottom: 20px;
  //  display: flex;
  //  justify-content: center;
  //}

  .user-friends {
    background: ${({ theme }) => theme.accountFriends.backgroundColor};
    border: ${({ theme }) => theme.accountFriends.border};
    margin: 25px 0 25px 0;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;

    @media screen and ${device('md', 'min')} {
      padding: 20px;
    }

    &__title {
      font-size: 18px;
      color: ${({ theme }) => theme.accountFriends.title.color};
      line-height: normal;

      b {
        color: ${({ theme }) => theme.accountFriends.title.counterColor};
        font-weight: 500;
      }

      @media screen and ${device('md', 'min')} {
        font-size: 20px;
        margin-left: 6px;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      gap: 14px 19px;
      align-items: center;
      padding: 7px 5px;

      &.empty {
        padding: 0;
      }

      &__item {
        width: 50px;
        height: 50px;
        position: relative;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }

        &__level {
          position: absolute;
          right: -4px;
          top: -4px;
          width: 19px;
          height: 19px;
          line-height: 15px;
          text-align: center;
          font-size: 10px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          z-index: 2;
          ${({ theme }) => theme.accountFriends.item.level};
        }
      }
    }
  }

  .custom-hint {
    position: relative;
    width: 0;
    transform: translate(15px, -35px);
    z-index: 100;
  }
`;

export const StyledActivityAddToFriendsImg = styled.img
  .attrs(props => ({
    src: props.theme.accountImages.addToFriends
  }))`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

export const StyledActivitySendMessageImg = styled.img
  .attrs(props => ({
    src: props.theme.accountImages.sendMessage
  }))`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

export const StyledActivitySendTipImg = styled.img
  .attrs(props => ({
    src: props.theme.accountImages.sendTip
  }))`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

export const StyledActivityTable = styled.ul`
  display: flex;
  flex-direction: column-reverse;

  li {
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;

    ${({ theme }) => `
      color: ${theme.readingPage.color};
      background-color: ${theme.accountActivityRowBg};
      border: ${theme.accountActivityRowBorder};
    `};

    @media screen and ${device('sm')} {
      padding: 20px 14px;
    }
    
    &:first-child {
      margin-bottom: 0;
    }

    .activity-list_icon {
      width: 41px;
      min-width: 41px;
      height: 41px;
      background-color: #2dae5c;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 25px;

      img {
        max-width: 100%;
      }

      @media screen and ${device('sm')} {
        width: 34px;
        min-width: 34px;
        height: 34px;
        margin-right: 10px;
      }
    }

    p {
      color: ${({ theme }) => theme.accountActivityItemTextColor};
    }

    .activity-list_title {
      margin-right: auto;
      font-size: 17px;

      p {
        text-wrap: normal;
        line-break: strict;
      }

      time {
        font-size: 14px;
        margin-top: 3px;
      }

      @media screen and ${device('sm')} {
        font-size: 14px;

        time {
          font-size: 12px;
        }
      }
    }

    .activity-list__sum {
      display: flex;
      align-items: center;
      justify-content: center;
      word-wrap: initial;
      white-space: nowrap;
      font-size: 16px;
      margin-left: 15px;

      span {
        display: block;
        margin-right: 8px;
      }

      p {
        height: 20px;
      }

      @media screen and ${device('sm')} {
        margin-left: 5px;
      }
    }

    @media screen and (max-width: 500px) {
      flex-wrap: wrap;
    }
  }
`;

export const StyledUserLevel = styled.div`
  .main-client-details {
      border-radius: 3px;
      padding: 20px;
      background-color: ${({ theme }) => theme.userLevel.background};
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px ${({ theme }) => theme.userLevel.border} solid;
      
      .right-side {
          span {
              font-size: 16px;
              line-height: 20px;
              color: ${({ theme }) => theme.userLevel.color};
              font-weight: 500;
          }
          ${hideOnMedia(800)}
      }
      
      .left-side {
          display: flex;
          
          .star-block {
              background-color: ${({ theme }) => theme.userLevel.starBg};
              height: 40px;
              width: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 100%;
              margin-right: 25px;
              margin-top: 5px;
              
              img {
                  height: 22px;
                  width: 22px;
              }
          }
          
          .details-block {
              display: flex;
              flex-direction: column;
              .level-info {
                  color: ${({ theme }) => theme.userLevel.levelColor};
                  font-size: 26px;
                  line-height: 26px;
                  margin-bottom: 5px;
                  font-weight: 500;
              }
              
              .points-info {
                  font-size: 14px;
                  color: ${({ theme }) => theme.userLevel.desColor};
                  font-weight: 400;
              }
          }
      }
  } 
  @media screen and ${device(800, 'max')} {
    .main-client-details {
      padding: 15px 20px;
      .right-side {
        font-size: 15px;
        line-height: 20px;
      } 
      .left-side {
        .star-block {
          height: 34px;
          width: 34px;
          margin-right: 16px;
          margin-top: 5px;
        }
        .details-block {
          .level-info {
            font-size: 22px;
            line-height: 22px;
          }
          .points-info {
            font-size: 12px;
          }
        }
      }  
    }  
  }  
`;

export const StyledUserLevelItem = styled.div`
    margin-top: 20px;
    padding: 20px;
    border: 1px ${({ theme }) => theme.userLevelItem.border} solid;
    background: ${({ theme }) => theme.userLevelItem.background};
    border-radius: 3px;
    .title-level-block {
        margin-bottom: 15px;
        font-size: 17px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .title-level-block b:last-child {
        color: ${({ theme }) => theme.userLevelItem.pointsColor};
        ${hideOnMedia(800)}
    }
    .title-level-block b:first-child {
        color: ${({ theme }) => theme.userLevelItem.titleColor};
    }
    .percent-block {
        background: ${({ theme }) => theme.userLevelItem.percentBg};
        width: 100%;
        border-radius: 5px;
        display: flex;
        overflow: hidden;
        
        .line-border {
            height: 20px;
            background-color: ${({ theme }) => theme.userLevelItem.percentColor};
            background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#2eab5b), to(#238346));
            background-image: -webkit-linear-gradient(top, #2eab5b, #238346);
            background-image: -o-linear-gradient(top, #2eab5b, #238346);
            background-image: linear-gradient(to bottom, #2eab5b, #238346);
            border-right: solid 1px ${({ theme }) => theme.userLevelItem.percentLine};
        }
        
        span {
            font-weight: bold;
            color: ${({ theme }) => theme.userLevelItem.percentColor};
            font-size: 13px;
            line-height: 20px;
            margin-left: 10px;
        }

        span.half {
            margin-left: -46px;
            color: #000000;
        }
    }
    .info-block {
        margin-top: 12px;
        font-size: 13px;
        color: ${({ theme }) => theme.userLevelItem.descColor};
        line-height: 17.5px;
    }
    @media screen and ${device(800, 'max')} {
      padding: 15px 20px;
      .title-level-block {
        margin-bottom: 12px;
      }
      .info-block {
        margin-top: 10px;
      }
    }
`;

export const SnapshotButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 5px;

    img {
        height: 20px;
        margin-right: 5px;
    }

    span {
        color: ${({ theme }) => theme.historyLink};
        transition: all 0.15s ease-in-out;
        font-size: 16px;
        line-height: 16px;
        font-weight: 500;
        text-transform: uppercase;
    }

    &:last-child {
        margin-left: 15px;
    }
    svg {
        margin-right: 5px;
        display: flex;
        fill: ${({ theme }) => theme.historyLink};
        transition: all 0.15s ease-in-out;
    }
    
    &:hover span, &:hover svg {
        color: ${({ theme }) => theme.historyLinkHover};
        fill: ${({ theme }) => theme.historyLinkHover};
    }
`

export const StyledClientBalancesContainer = styled.div`
    background: ${({ theme }) => theme.accountFriends.backgroundColor};
    border: ${({ theme }) => theme.accountFriends.border};
    margin: 0 0 25px 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const StyledClientBalancesTitle = styled.span`
    font-size: 18px;
    color: ${({ theme }) => theme.accountFriends.title.color};
    line-height: normal;

    @media screen and ${device('md', 'min')} {
        font-size: 20px;
        margin-left: 6px;
    }
`

export const StyledClientBalancesList = styled.div`
    margin: 15px -20px -20px -20px;
`

export const StyledClientBalancesListItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.accountBalances.bgItem};
    padding-left: 26px;
    padding-right: 26px;
    position: relative;
    
    span {
        font-size: 15px;
        color: ${({ theme }) => theme.accountFriends.title.color};
    }

    & span:first-child {
        text-align: left;
    }

    & span:last-child {
        text-align: right;
    }

    @media screen and (max-width: 880px) {
        span {
            font-size: 13px;
        }
    }

    @media screen and (max-width: 600px) {
        span {
            font-size: 15px;
        }
    }
`

export const StyledClientBalancesBtnBlock = styled.div`
    position: absolute;
    right: 26px;
    top: 50%;
    transform: translate(0, -50%);
    background-color: ${({ theme }) => theme.accountBalances.bgItemBtn};

    button {
        width: auto !important;
        height: auto !important;
        font-size: 12px !important;
        line-height: 16px !important;
        padding: 2px 6px 3px !important;
        margin: 0 0 0 5px !important;
        float: none !important;
        vertical-align: top !important;
        text-transform: none !important;
        border: 0 !important;
    }
`

export const StyledClientEstimatedBlock = styled.div`
    margin: 35px -20px -20px -20px;
    
    & div {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    
    & div span {
        width: 50%;
    }
`